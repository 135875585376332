import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react'
import elasticlunr, { Index } from 'elasticlunr'
import { keyBy, groupBy } from 'lodash'
import slugify from 'slugify'
import DateFilter from '../components/date-filter'
import EventList from '../components/event-list'
import EventsByDate from '../components/events-by-date'
import SearchFilter from '../components/search-filter'
import SelectFilter, { SelectFilterOption } from '../components/select-filter'
import Tab from '../components/tab'
import Tabs from '../components/tabs'
import TagFilter, { TagFilterOption } from '../components/tag-filter'
import ThemeBanner from '../components/theme-banner'
import { useVeeziWebSessionsAll } from '../hooks/veezi-web-sessions'
import styles from './events.module.css'
import SEO from '../components/seo'
import {
  getAccessibilityMap,
  filterSessionsByDate,
  getDatesWithEvents,
  groupEventsByCategory,
  getCategoriesToShow,
  groupEventsByTheme,
  getThemesToShow,
  containsAll,
  getSearchIndex,
} from '../util/events'

const searchIcon = (
  <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.427 15.427a8.5 8.5 0 112-2l5.585 5.585c.55.55.546 1.431 0 1.976l-.023.023a1.398 1.398 0 01-1.976 0l-5.585-5.585-.001.001zM8.5 15a6.5 6.5 0 100-13 6.5 6.5 0 000 13z"
      fill="#FFF"
    />
  </svg>
)

// elasticlunr.clearStopWords()

export const FilmsTemplate = ({
  films,
  categories,
  themes,
  venues,
  location: { hash },
  locations,
  searchIndex,
}) => {
  const tabs = ['themes', 'dates', 'accessibility', 'categories', 'locations', 'search']
  let extraFilter = useRef(null)

  const [tab, setTab] = useState('themes')
  const [selectedCategory, setSelectedCategory] = useState('all')
  const [selectedLocation, setSelectedLocation] = useState('golden-mile-tower')
  const [selectedTheme, setSelectedTheme] = useState('all')
  const [selectedAccessibilities, setSelectedAccessibilities] = useState({
    wheelchair: true,
  })
  const [selectedDate, setSelectedDate] = useState(dayjs())
  const [searchKeyword, setSearchKeyword] = useState('')
  const [dateRange, setDateRange] = useState([
    dayjs(selectedDate).startOf('week'),
    dayjs(selectedDate)
      .startOf('week')
      .add(6, 'days'),
  ])

  useEffect(() => {
    const defaultTab = 'themes'
    const resetChildren = () => {
      setSelectedCategory('all')
      setSelectedLocation('golden-mile-tower')
      setSelectedTheme('all')
    }
    if (hash && hash.includes('-')) {
      const t = hash.substring(1, hash.indexOf('-'))
      const id = hash.substring(hash.indexOf('-') + 1, hash.length)
      if (tabs.includes(t)) {
        if (tab !== t) setTab(t)
        if (t === 'categories')
          (id === 'all' ||
            (categories || []).findIndex(c => c.id === id) > -1) &&
            setSelectedCategory(id)
        else if (t === 'themes')
          (id === 'all' || (themes || []).findIndex(t => t.id === id) > -1) &&
            setSelectedTheme(id)
        else if (t === 'locations') {
          setSelectedLocation(id)
        }
      } else {
        setTab(defaultTab)
        resetChildren()
      }
    } else {
      const t =
        hash && tabs.includes(hash.substring(1, hash.length))
          ? hash.substring(1, hash.length)
          : defaultTab
      if (tab !== t) setTab(t)
      resetChildren()
    }
  }, [hash, tabs, tab, categories, themes])

  const startDate = dateRange[0]
  const endDate = dateRange[1]
  const veeziWebSessions = (useVeeziWebSessionsAll() || []).filter(
    s => films.findIndex(e => e.veeziFilmId === s.FilmId) > -1
  )

  const groupByLocations = groupBy(veeziWebSessions, s => {
    const location = locations.find(l => l.screens.includes(s.ScreenId))
    if (location) return slugify(location.title).toLowerCase()
    return 'golden-mile-tower'
  })

  let veeziData = tab === 'locations' ? ((groupByLocations && groupByLocations[selectedLocation]) || []) : veeziWebSessions
  const veeziWebSessionsForDate = useMemo(
    () =>
      filterSessionsByDate({
        selectedDate,
        startDate,
        endDate,
        veeziWebSessions: veeziData,
      }),
    [selectedDate, startDate, endDate, veeziData]
  )

  const datesWithSession = useMemo(
    () => getDatesWithEvents({ veeziWebSessions: veeziData }),
    [veeziData]
  )

  const filmsByCategory = useMemo(() => groupEventsByCategory(films), [films])

  const categoriesToShow = useMemo(
    () => getCategoriesToShow(categories, filmsByCategory),
    [filmsByCategory, categories]
  )

  const filmsByTheme = useMemo(() => groupEventsByTheme(films), [films])

  const themesToShow = useMemo(() => getThemesToShow(themes, filmsByTheme), [
    filmsByTheme,
    themes,
  ])

  const venueAccessibilityMap = useMemo(() => getAccessibilityMap(venues), [
    venues,
  ])

  films = films.map(f => {
    if (f.themes && f.themes.length)
      f.themes = f.themes.filter(
        ft => themes.findIndex(t => t.id === ft.id) > -1
      )

    f.accessibility = veeziData
      .filter(s => f.veeziFilmId === s.FilmId)
      .reduce((a, s) => {
        const accessibility = venueAccessibilityMap[s.ScreenId]
        if (accessibility && accessibility.length) {
          a.push(...accessibility)
        }

        if (
          (f.screenings || []).find(
            fs => fs.veeziSessionId === s.Id && fs.closedCaptions
          )
        ) {
          a.push('cc')
        }

        return a
      }, [])
    f.accessibility = Array.from(new Set(f.accessibility))
    f.accessibility.push(...(f.subtitles || []).map(s => `subtitles-${s}`))

    return f
  })

  // reset filter
  extraFilter.current = null
  let filmsToShow

  if (tab === 'categories') {
    filmsToShow =
      selectedCategory === 'all'
        ? films
        : filmsByCategory[selectedCategory] || []
  } else if (tab === 'themes') {
    filmsToShow = selectedTheme === 'all' ? films : filmsByTheme[selectedTheme]
  } else if (tab === 'accessibility') {
    const filters = Object.keys(selectedAccessibilities).reduce((f, k) => {
      if (selectedAccessibilities[k]) f.push(k)
      return f
    }, [])

    filmsToShow =
      !filters || !filters.length
        ? films
        : films.filter(
          f => f.accessibility && containsAll(filters, f.accessibility || [])
        )

    extraFilter.current = session => {
      const accessibility = venueAccessibilityMap[session.ScreenId] || []
      return accessibility && containsAll(filters, accessibility || [])
    }
  } else if (tab === 'locations') {
    filmsToShow = filmsByCategory[selectedLocation]
    if (selectedLocation === 'watch-at-home') {
      filmsToShow = filmsByTheme['Y-ANaRq64']
    } else {
      const filmObj = keyBy(films, 'veeziFilmId')
      const veeziFilmObj = keyBy(veeziData, 'FilmId')
      filmsToShow = Object.keys(veeziFilmObj).map(filmId => {
        return filmObj[filmId]
      }).filter(x => x)
    }
  } else if (tab === 'dates') {
    const filmIdsForDate = veeziWebSessionsForDate.map(s => s.FilmId)
    filmsToShow = films.filter(f => filmIdsForDate.includes(f.veeziFilmId))
  } else if (tab === 'search') {
    if (searchKeyword && searchKeyword.trim() !== '' && searchIndex) {
      elasticlunr.clearStopWords()
      const store = Index.load(searchIndex)
      const search = getSearchIndex(store, searchKeyword, 'film')
      filmsToShow = films.filter(
        f => search.findIndex(s => f.slug === s.slug) > -1
      )
    } else {
      filmsToShow = films
    }
  }

  let eventList
  if (tab === 'dates') {
    if (selectedDate === 'all') {
      eventList = (
        <EventsByDate
          events={filmsToShow}
          veeziWebSessions={veeziWebSessionsForDate}
        ></EventsByDate>
      )
    } else {
      const filmsWithSessions = filmsToShow.map(f =>
        Object.assign({}, f, {
          sessions: (veeziWebSessionsForDate || []).filter(
            s => s.FilmId === f.veeziFilmId
          ),
        })
      )
      eventList = <EventList events={filmsWithSessions} />
    }
  } else {

    eventList = (
      <EventList events={filmsToShow} extraFilter={extraFilter.current} />
    )
  }

  const onTabClick = useCallback(tab => {
    window.location = '#' + tab
    setTab(tab)
  }, [])

  const onCategoryClick = useCallback(category => {
    window.location.hash = '#categories-' + category
  }, [])

  const onLocationClick = useCallback(location => {
    window.location.hash = '#locations-' + location
  }, [])

  const onThemeClick = useCallback(theme => {
    window.location.hash = '#themes-' + theme
  }, [])

  return (
    <>
    <SEO 
      title={"Movies showing now Singapore | New & latest movies Singapore"}
      description={"The latest movies showing now in The Projector cinema. Movie showtimes and buy movie tickets for current and new movies, indie, cult classics & foreign movies."}
    />
    <div className="container flex flex-col items-stretch">
      <h1 className={styles.title}>
        Films: What's showing
        <button
          className={styles.searchIconBtn}
          onClick={() => onTabClick('search')}
        >
          <span>Search</span>
          {searchIcon}
        </button>
      </h1>
      <Tabs
        title="Browse by"
        selectClass={tab === 'search' ? styles.mobileHidden : ''}
        className={styles.tabs}
      >
        <Tab
          title="Programmes"
          dropdownTitle="Browse By Programmes"
          isActive={tab === 'themes'}
          onClick={() => onTabClick('themes')}
        >
          <SelectFilter
            onChange={option => onThemeClick(option.value)}
            dropdownTitle="All Programmes"
          >
            {themesToShow.map((theme, i) => (
              <SelectFilterOption
                key={i}
                value={theme.id}
                title={theme.title}
                isSelected={selectedTheme === theme.id}
              />
            ))}
          </SelectFilter>
        </Tab>
        <Tab
          title="Locations"
          dropdownTitle="Browse By Locations"
          isActive={tab === 'locations'}
          onClick={() => onTabClick('locations-golden-mile-tower')}
        >
          <SelectFilter
            onChange={option => onLocationClick(option.value)}
            dropdownTitle="All Genres"
            addAll={false}
          >
            <SelectFilterOption
              key='golden-mile-tower'
              value='golden-mile-tower'
              title='Golden Mile Tower'
              isSelected={selectedLocation === 'golden-mile-tower'}
            />
            {/* <SelectFilterOption
              key="riverside-point"
              value="riverside-point"
              title="Riverside Point"
              isSelected={selectedLocation === 'riverside-point'}
            /> */}
            <SelectFilterOption
              key="cineleisure"
              value="cineleisure"
              title="Cineleisure"
              isSelected={selectedLocation === 'cineleisure'}
            />
            {/* <SelectFilterOption
              key="picturehouse-xtra"
              value="picturehouse-xtra"
              title="Picturehouse Xtra"
              isSelected={selectedLocation === 'picturehouse-xtra'}
            /> */}
            {/* <SelectFilterOption
              key="watch-at-home"
              value="watch-at-home"
              title="Watch At Home"
              isSelected={selectedLocation === 'watch-at-home'}
            /> */}
          </SelectFilter>
        </Tab>
        <Tab
          title="Dates"
          dropdownTitle="Browse By Dates"
          isActive={tab === 'dates'}
          onClick={() => onTabClick('dates')}
        >
          <DateFilter
            onChange={setSelectedDate}
            onRangeChange={r => setDateRange([dayjs(r[0]), dayjs(r[6])])}
            showHistory={false}
            showAll={true}
            value={selectedDate}
            showCalendarWeeks={true}
            allowedDates={datesWithSession}
          />
        </Tab>
        <Tab
          title="Accessibility"
          dropdownTitle="Browse By Accessibility"
          isActive={tab === 'accessibility'}
          onClick={() => onTabClick('accessibility')}
        >
          <TagFilter
            onChange={value =>
              setSelectedAccessibilities(
                Object.assign({}, selectedAccessibilities, {
                  [value]: !selectedAccessibilities[value],
                })
              )
            }
          >
            <TagFilterOption
              title="Wheelchair-Friendly"
              value="wheelchair"
              isSelected={selectedAccessibilities['wheelchair']}
            />
            <TagFilterOption
              title="Closed Captions (CC)"
              value="cc"
              isSelected={selectedAccessibilities['cc']}
            />
            <TagFilterOption
              title="English Subtitles"
              value="subtitles-en"
              isSelected={selectedAccessibilities['subtitles-en']}
            />
            <TagFilterOption
              title="Chinese Subtitles"
              value="subtitles-zh"
              isSelected={selectedAccessibilities['subtitles-zh']}
            />
          </TagFilter>
        </Tab>
        <Tab
          title="Genres"
          dropdownTitle="Browse By Genres"
          isActive={tab === 'categories'}
          onClick={() => onTabClick('categories')}
        >
          <SelectFilter
            onChange={option => onCategoryClick(option.value)}
            dropdownTitle="All Genres"
          >
            {categoriesToShow.map((category, i) => (
              <SelectFilterOption
                key={i}
                value={category.id}
                title={category.title}
                isSelected={selectedCategory === category.id}
              />
            ))}
          </SelectFilter>
        </Tab>
        <Tab
          title="Search"
          titleIcon={searchIcon}
          isActive={tab === 'search'}
          headerClass={styles.search}
          onClick={() => onTabClick('search')}
          hidden={true}
        >
          <SearchFilter
            onChange={k => setSearchKeyword(k)}
            onClose={() => onTabClick('categories')}
          />
        </Tab>
      </Tabs>
      {tab === 'dates' ? <ThemeBanner date={selectedDate} /> : null}
      {filmsToShow && filmsToShow.length ? (
        eventList
      ) : (
        <div className={styles.noEvents}>No films found.</div>
      )}
    </div>
    </>
  )
}

FilmsTemplate.propTypes = {
  films: PropTypes.arrayOf(PropTypes.object),
}
